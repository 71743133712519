import VisualElicit from "../../images/research/visualElicitation/visElicitation.gif";
import BivariateVis from "../../images/research/VisualizingBivariate/Line_Uncertainty.PNG";
import Dashboard from "../../images/research/Dashboard/dashboard.png";

export const researchProjects = [
  {
    title: "When do data visualizations persuade?",
    subTitle:
      "The impact of prior attitudes on learning about correlations from scatterplot visualizations",
    image: BivariateVis,
    description:
      "Data visualizations are vital to scientific communication on critical issues such as public health, climate change, and socioeconomic policy. They are often designed not just to inform, but to persuade people to make consequential decisions (e.g., to get vaccinated). Are such visualizations persuasive, especially when audiences have beliefs and attitudes that the data contradict?",
    link: "https://dl.acm.org/doi/abs/10.1145/3544548.3581330",
  },
  {
    title:
      "The Impact of Elicitation and Contrasting Narratives on Engagement, Recall and Attitude Change With News Articles Containing Data Visualization",
    subTitle:
      "The impact of belief elicitation on how the user engages with and accurately remembers data, as well as the likelihood to change their attitudes.",
    image: VisualElicit,
    description:
      "News articles containing data visualizations play an important role in informing the public on issues ranging from public health to politics. Recent research on the persuasive appeal of data visualizations suggests that prior attitudes can be notoriously difficult to change. Inspired by an NYT article, we designed two experiments to evaluate the impact of elicitation and contrasting narratives on attitude change, recall, and engagement.",
    link: "https://ieeexplore.ieee.org/abstract/document/10409610",
  },
  {
    title: "Community Resource Center Social Determinants of Health Dashboard",
    subTitle:
      "Provide a dynamic web-based tool that informs CRC policy, identifies CRC service needs, and supports decision making. ",
    image: Dashboard,
    description:
      "The dashboard will show health inequities and opportunities within each CRC’s service area or neighborhood.",
    link: "https://www.aphinnovation.org/copy-of-community-engagement-toolkit",
  },
];
