import React from "react";
// import styles from "../../styles/layout.module.css";
import { researchProjects } from "./researchContent";
import "./Research.css";
import ImageCard from "../../components/main/imageCard/ImageCard";

function Research(props) {
  return (
    <div className={"Rcollection"}>
      {researchProjects.map((p, i) => {
        return (
          <ImageCard
            imageSrc={p.image}
            key={`project_${i}`}
            onClick={(event) => {
              window.open(p.link, "_blank");
            }}
          >
            <h2>{p.title}</h2>
            <h4>{p.subTitle}</h4>
            <p className="desc">{p.description}</p>
          </ImageCard>
        );
      })}
    </div>
  );
}

export default Research;
