import React from "react";
import classes from "../../styles/layout.module.css";
import profilePic from "../../images/profile_pic_s.jpg";
import InteractiveBio from "../../components/vis/interactiveBio/InteractiveBio";
import "./Bio.css";

export default function Bio() {
  return (
    <div className={`${classes.cardNoHov} bioLayout`}>
      <div className="bioHeader">
        <img
          src={profilePic}
          className="profile"
          alt={"Milad Rogha"}
        />
        <h1>
          <code className="customCode">{"> Hello, world!_"}</code>
        </h1>
      </div>
      <div className="textHolder">
        <p className="tx1">
          Data Scientist with four years of experience specializing in machine
          learning and data analysis, leveraging skills in Python, R, and
          JavaScript to deliver innovative solutions. Proven ability to enhance
          Large Language Model performance and improve data accessibility
          through cloud-based platforms, with a focus on precision and
          efficiency. Committed to applying analytical skills to provide
          impactful insights in data-driven environments.
        </p>

        <p className="tx1">
          I am a Ph.D. student at the Department of Computer Science, College of
          Computing and Informatics, University of North Carolina at Charlotte.
          My research interests include human-AI interaction, data
          visualization, explainable AI, and social computation. The rise in the
          application of artificial intelligence, especially in the fields
          requiring critical decision-making, raised questions regarding the
          autonomy and reliability of the predictions made by these models. My
          research focus is twofold: How can data visualization techniques
          increase the efficiency and efficacy of AI-human decision-making? How
          do we convey the model results to lay users in such a way that it is
          persuasive?
        </p>
      </div>
      <div className="visContainer">
        <InteractiveBio />
      </div>
    </div>
  );
}
