import React from "react";
import "./App.css";
import { AuthProvider } from "./contexts/AuthContext";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import classes from "./styles/layout.module.css";
import Landing from "./pages/landing/Landing";
import NavBar from "./components/main/navBar/NavBar";
import Bio from "./pages/bio/Bio";
import Blog from "./pages/blog/Blog";
import BlogAdmin from "./pages/blog/BlogAdmin.js";
import CV from "./pages/cv/CV.jsx";
import Research from "./pages/research/Research";
import Signup from "./pages/login/Signup";
import SignIn from "./pages/login/SignIn";
import ForgotPassword from "./pages/login/ForgotPassword";
import Dashboard from "./pages/dashboard/Dashboard";
import StudioLogo from "./images/Studio_Rogha.png";
import Teaching from "./pages/teaching/Teaching";
import PrivateRoute from "./components/main/privateroute/PrivateRoute";

function App() {
  const location = useLocation();

  return (
    <AuthProvider>
      <header className={classes.headerContainer}>
        <img
          src={StudioLogo}
          alt="Studio Rogha"
          className={classes.logo}
        />
        <nav>
          <NavBar cPath={location.pathname} />
        </nav>
      </header>
      <div className={classes.mainContainer}>
        {/* A <Switch> looks through its children <Route>s and
                     renders the first one that matches the current URL. */}
        <Routes>
          <Route
            path="/"
            element={<Navigate to="/home" />}
          />
          <Route
            exact
            path="/home"
            element={<Landing />}
          />
          <Route
            exact
            path="/bio"
            element={<Bio />}
          />
          <Route
            exact
            path="/cv"
            element={<CV />}
          />
          <Route
            exact
            path="/blog"
            element={<Blog />}
          />
          <Route
            exact
            path="/teaching"
            element={<Teaching />}
          />
          <Route
            exact
            path="/research"
            element={<Research />}
          />
          <Route
            exact
            path="/signup"
            element={<Signup />}
          />
          <Route
            exact
            path="/login"
            element={<SignIn />}
          />
          <Route
            exact
            path="/forgot-password"
            element={<ForgotPassword />}
          />
          <Route
            exact
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          ></Route>
          <Route
            exact
            path="/blog-admin"
            element={
              <PrivateRoute>
                <BlogAdmin />
              </PrivateRoute>
            }
          ></Route>
        </Routes>
      </div>

      <footer>
        <p> © STUDIO ROGHA.</p>
      </footer>
    </AuthProvider>
  );
}

export default App;
