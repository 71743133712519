import React, { Fragment } from "react";
import classes from "../../styles/CV.module.css";
import education from "../../images/cv/icons8-education-100.png";
import skills from "../../images/cv/icons8-skills-100.png";
import publications from "../../images/cv/icons8-paper-100.png";
import { Card } from "react-bootstrap";
import resume from "../../files/cv-Milad Rogha.pdf";

const CV = () => {
  return (
    <Fragment>
      <div className={classes.header}>
        <h2>Curriculum vitae</h2>
        <div className={classes.download}>
          <span className={classes.wave}>
            <a
              className={classes.dlLink}
              href={resume}
              download="Milad Rogha-resume.pdf"
            >
              Download the PDF
            </a>
          </span>
        </div>
      </div>
      <Card className={classes.card}>
        <Card.Subtitle>
          <>
            <Card.Img
              src={education}
              variant="top"
              className={classes.imgs}
            ></Card.Img>
            <strong className={classes.sect}>Education</strong>
          </>
        </Card.Subtitle>

        <ul>
          <li className={classes.infoCard}>
            <strong>
              University of North Carolina at Charlotte (2021 - Present), Ph.D.
              Computer Science{" "}
            </strong>
            <br />
            Selected coursework: Algorithm and Data Structure, Intelligent
            Systems, Machine Learning, Database Systems, Human-Centered Design,
          </li>

          <li className={classes.infoCard}>
            <strong>
              University of North Carolina at Charlotte (2016- 2019), M.ARCH.
              Architecture{" "}
            </strong>
            <br />
            Capstone Project: Giraffe: A multivariant Design System to Generate
            Contextually and Environmentally Efficient Architecture <br />
            Selected Coursework: Computation Methods, Computational Practice
          </li>
          <li className={classes.infoCard}>
            <strong>
              Isfahan University of Technology (2008-2013), B.S. Electrical and
              Computer Engineering -Control Systems{" "}
            </strong>
            <br />
            Selected Coursework: Engineering probability, Logic Circuits,
            Applied Linear Algebra, Computer Architecture, Industrial Management
            & Economics, Intelligent Control Systems, Microprocessor
          </li>
        </ul>
      </Card>
      <Card className={classes.card}>
        <Card.Subtitle>
          <>
            <Card.Img
              src={skills}
              variant="top"
              className={classes.imgs}
            ></Card.Img>
            <strong className={classes.sect}>Skills</strong>
          </>
        </Card.Subtitle>

        <ul>
          <li className={classes.infoCard}>
            <strong>Programming Languages </strong>
            <br />
            Python, JavaScript
          </li>
          <li className={classes.infoCard}>
            <strong>Programming Frameworks:</strong>
            <br />
            NodeJS
          </li>
          <li className={classes.infoCard}>
            <strong>Machine Learning / Neural Networks:</strong>
            <br />
            Scikit Learn, Tensorflow, Keras
          </li>

          <li className={classes.infoCard}>
            <strong>Data Science:</strong>
            <br />
            Pandas, Numpy, Tableau, PowerBI
          </li>
          <li className={classes.infoCard}>
            <strong>Database:</strong>
            <br />
            MySQL , MongoDB
          </li>
          <li className={classes.infoCard}>
            <strong>DevOps:</strong>
            <br />
            Git
          </li>
        </ul>
      </Card>
      <Card className={classes.card}>
        <Card.Subtitle>
          <>
            <Card.Img
              src={publications}
              variant="top"
              className={classes.imgs}
            ></Card.Img>
            <strong className={classes.sect}>Publications</strong>
          </>
        </Card.Subtitle>

        <div className={classes.infoCard}>
          <strong>
            The impact of elicitation and contrasting narratives on engagement,
            recall and attitude change with news articles containing data
            visualization | 2024 |{" "}
            <a href={"https://ieeexplore.ieee.org/abstract/document/10409610/"}>
              [Link]
            </a>
          </strong>{" "}
          <br />
          Milad Rogha, Subham Sah, Alireza Karduni, Doug Markant, Wenwen Dou
          <br /> IEEE Transactions on Visualization and Computer Graphics
        </div>

        <div className={classes.infoCard}>
          <strong>
            BIM as a Mitigation Tool for COVID-19 Management on a University
            Campus | 2023 |{" "}
            <a
              href={"https://ascelibrary.org/doi/abs/10.1061/9780784485248.021"}
            >
              [Link]
            </a>
          </strong>{" "}
          <br />
          Chen, Don, Turner Cash, Milad Rogha, Wenwu Tang, Cynthia Gibas, and
          Mariya Munir
          <br /> In Computing in Civil Engineering 2023, pp. 169-176.
        </div>

        <div className={classes.infoCard}>
          <strong>
            When do data visualizations persuade? The impact of prior attitudes
            on learning about correlations from scatterplot visualizations |
            2023 |{" "}
            <a href={"https://doi.org/10.1145/3544548.3581330"}>[Link]</a>
          </strong>{" "}
          <br />
          Doug Markant, Milad Rogha, Alireza Karduni, Ryan Wesslen, Wenwen Dou
          <br /> CHI '23: Proceedings of the 2023 CHI Conference on Human
          Factors in Computing Systems
        </div>

        <div className={classes.infoCard}>
          <strong>
            Can data visualizations change minds? identifying mechanisms of
            elaborative thinking and persuasion | 2022 |{" "}
            <a href={"https://ieeexplore.ieee.org/abstract/document/9975360"}>
              [Link]{" "}
            </a>
          </strong>
          <br />
          Doug Markant, Milad Rogha, Alireza Karduni, Ryan Wesslen, Wenwen Dou
          <br />
          2022 IEEE Workshop on Visualization for Social Good (VIS4Good)
        </div>
      </Card>
    </Fragment>
  );
};

export default CV;
