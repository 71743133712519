import React, { useRef, useEffect } from "react";
import * as d3 from "d3";

export default function InteractiveBio() {
  const svgRef = useRef(null);
  const width = "80%";
  const height = 100;
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    svg.attr("width", width).attr("height", height);

    var graph = svg
      .append("g")
      .attr("name", "graph")
      .append("line")
      .attr("x1", 0)
      .attr("y1", 50)
      .attr("x2", 300)
      .attr("y2", 50)
      .attr("stroke", "gray");
    svg
      .append("circle")
      .attr("cx", 10)
      .attr("cy", 50)
      .attr("r", 8)
      .attr("fill", "steelblue");
  }, []);

  return <svg ref={svgRef} />;
}
