import React, { useState } from "react";
import "./Teaching.css";
import ideaSeminarPic from "../../images/teaching/courses//ideaseminar/advancedScripting-cover.jpg";
import ImageCard from "../../components/main/imageCard/ImageCard";

export default function Teaching() {
  const [detailState, setDetailState] = useState(false);

  function handleDetails() {
    setDetailState((prev) => !prev);
  }

  return (
    <div className="teachLayout">
      <ImageCard
        className="teachCard"
        onClick={handleDetails}
        imageSrc={ideaSeminarPic}
      >
        <h2>Idea Seminar</h2>
        <p>
          {" "}
          School of Architecture
          <br />
          UNC Charlotte
          <br />
          Fall 2021{" "}
        </p>
      </ImageCard>

      {detailState ? (
        <div className="classDesc">
          <h2>Introduction</h2>
          <p style={{ padding: "1vmin", overflow: "auto" }}>
            {" "}
            <strong>INTRODUCTION</strong> Computer-Aided Drawing (CAD) software
            is now an inseparable tool for architects. While there are many
            advanced programs and computational tools out there, each program
            introduces various limitations. Scripting helps the user overcome
            these limitations by user-written programs. Scripting can also
            benefit the designer to explore information and obtain knowledge by
            working with and analyzing big data. In Parametric Architecture,
            scripting helps designers design the process, not the outcome, and
            enables them to control complexity beyond human capabilities. Some
            of the scripting applications in Architecture are: developing
            plug-ins for modeling/BIM software, generative design, form-finding,
            agent-based modeling, digital fabrication, and data-driven design.
            This course focuses on Object-Oriented Programming (OOP), one of the
            major programming paradigms. The premise of the course is to use OOP
            to built-up skill sets that are most needed to tackle future
            problems in the field or advanced research. The goal is to cultivate
            logical thinking in computational methods, develop algorithmic
            thinking, and implement object-oriented scripting throughout the
            course via a set of relative assignments and projects.
          </p>
        </div>
      ) : null}
    </div>
  );
}
