import menuIcon from "../../../images/navbar/icons8-menu.svg";
import menuIconClose from "../../../images/navbar/icons8-close.svg";

//Import icon images
import iconTeaching from "../../../images/navbar/icons8-training-100.png";
import iconResume from "../../../images/navbar/icons_CV-B.png";
// import iconBlog from "../../../images/navbar/icons_Blog-B.png";
import iconBio from "../../../images/navbar/icons_Bio-B.png";
import iconHome from "../../../images/navbar/icons_Home-B.png";
// import iconLogIn from "../../../images/navbar/icons_Login-B.png";
// import iconSignOut from "../../../images/navbar/icons_Sign-out-B.png";
// import iconDashboard from "../../../images/navbar/icons_Dashboard-B.png";
// import iconBlogPost from "../../../images/navbar/icons_BlogPost-B.png";
import iconResearch from "../../../images/navbar/icons_Research-B.png";

// Import Wihte icons
import iconTeachingW from "../../../images/navbar/icons8-training-100-w.png";
import iconResumeW from "../../../images/navbar/icons_CV-W.png";
// import iconBlogW from "../../../images/navbar/icons_Blog-W.png";
import iconBioW from "../../../images/navbar/icons_Bio-W.png";
import iconHomeW from "../../../images/navbar/icons_Home-W.png";
// import iconLogInW from "../../../images/navbar/icons_Login-W.png";
// import iconSignOutW from "../../../images/navbar/icons_Sign-out-W.png";
// import iconDashboardW from "../../../images/navbar/icons_Dashboard-W.png";
// import iconBlogPostW from "../../../images/navbar/icons_BlogPost-W.png";
import iconResearchW from "../../../images/navbar/icons_Research-W.png";

const menuItemsData = [
  {
    name: "Home",
    route: "/",
    iconB: iconHome,
    iconW: iconHomeW,
  },
  {
    name: "Bio",
    route: "/bio",
    iconB: iconBio,
    iconW: iconBioW,
  },
  {
    name: "CV",
    route: "/cv",
    iconB: iconResume,
    iconW: iconResumeW,
  },
  // {
  //   name: "Blog",
  //   route: "/blog",
  //   iconB: iconBlog,
  //   iconW: iconBlogW,
  // },
  {
    name: "Research",
    route: "/research",
    iconB: iconResearch,
    iconW: iconResearchW,
  },
  {
    name: "Teaching",
    route: "/teaching",
    iconB: iconTeaching,
    iconW: iconTeachingW,
  },
];

export const unAuthMenuItemsData = [
  ...menuItemsData,
  // {
  //   name: "Log In",
  //   route: "/login",
  //   iconB: iconLogIn,
  //   iconW: iconLogInW,
  // },
];

export const authMenuItemsData = [
  ...menuItemsData,
  // {
  //   name: "Dashboard",
  //   route: "/dashboard",
  //   iconB: iconDashboard,
  //   iconW: iconDashboardW,
  // },
  // {
  //   name: "Write",
  //   route: "/blog-admin",
  //   iconB: iconBlogPost,
  //   iconW: iconBlogPostW,
  // },

  // {
  //   name: "Sign out",
  //   route: "/home",
  //   iconB: iconSignOut,
  //   iconW: iconSignOutW,
  // },
];

export const menuIcons = {
  open: { route: menuIcon },
  close: { route: menuIconClose },
};
