import React from "react";
import "./ImageCard.css";

export default function ImageCard({ children, imageSrc, ...props }) {
  return (
    <div
      className="ImgCard"
      onClick={props.onClick}
    >
      <img
        src={imageSrc}
        alt="Clickable card"
        className="ImgCardImg"
      />
      <div className="ImgCardContent">{children}</div>
      <div className="Note"> {">Click to Learn More<"} </div>
    </div>
  );
}
