import React, { useEffect, useState } from "react";
import ContentHolder from "../../components/blog/contentHolder/ContentHolder";
import classes from "../../styles/layout.module.css";
import classes2 from "./Blog.module.css";
import Loading from "../../images/icons8-loading.png";

export default function Blog(props) {
  //Loading state
  const [isLoading, setIsLoading] = useState(true);
  const [loadedPosts, setLoadedPosts] = useState([]);

  useEffect(() => {
    //Load data from DB
    fetch("https://studiorogha-default-rtdb.firebaseio.com/blog.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setLoadedPosts(data);
        setIsLoading(false);
        console.log(data);
      });
  }, []);

  if (isLoading) {
    return (
      <section>
        <img
          src={Loading}
          alt="Loading"
          className={classes.loading}
        />
      </section>
    );
  } else {
    const keys = Object.keys(
      loadedPosts || {
        title: "",
        subTitle: "",
        content: "",
        thumbnail: "",
        key: "",
      }
    );

    function HandleLoadedPosts() {
      const post_elems = [];
      if (loadedPosts) {
        for (let author_Id in loadedPosts) {
          if (author_Id) {
            for (let postId in loadedPosts[author_Id]) {
              post_elems.push(
                keys.map((k, i) => {
                  const bPost = loadedPosts[author_Id][postId];
                  // console.log(bPost);
                  return (
                    <ContentHolder
                      title={bPost.title}
                      subTitle={bPost.date}
                      content={bPost.content}
                      thumbnail={bPost.thumbnail}
                      key={i}
                    />
                  );
                })
              );
            }
          }
        }
        return post_elems;
      } else return false;
    }

    return (
      <div className={classes.mainContainer}>
        <div className={classes2.postsContainer}>
          {loadedPosts ? HandleLoadedPosts() : "No posts yet!"}
        </div>
      </div>
    );
  }
}
